import Rest from '@/services/Rest';

/**
 * @typedef {TicketMessage}
 */
export default class TicketMessage extends Rest {
    /**
     * @type {String}
     */
    static resource = '/tickets/message'
}
