<template>
    <div >
        <div class="page-breadcrumb">
            <div class="col-12">
                <div class="row">
                    <div class="col-7 align-self-center pl-4">
                        <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">suporte QS</h3>
                        <div class="d-flex align-items-center">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb m-0 p-0">
                                    <li class="breadcrumb-item"><a href="?app=dashboard.php">dashboard / suporte / chamado</a></li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <div class="col-5 align-self-center text-right">
                                        </div>
                </div>
            </div>
        </div>
        <div class="col-12 bg-white my-4">
            <form  action="" class="needs-validation py-4 px-2" novalidate="">
                <div class="col-12">
                    <div v-show="id" class="row">
                        <div class="col-12">
                            <div class="col-12 px-0 pb-2">
                                <h3 class="page-title text-truncate text-dark font-weight-medium mb-1">Interações</h3>
                            </div>
                          
                                <div class="form-group" v-for="item, index in ticket.messages" :key="item.id">
                                    <label class="pl-0">{{item.created_at}}</label>
                                    <div class="text-content-exibir"><b>Sr(a).</b> {{item.user.first_name}} {{item.user.last_name}} {{item.message}}</div>
                                    <div class="row">

                                    <div class="col-12 col-md-12 col-lg-12 pt-3"  v-for="image in item.images" :key="image.id">
                                        <label>Anexa imagem ou print do erro  {{index + 1}}</label>
                                        <vue-upload-multiple-image
                                    
                                            :data-images="[{path: image.path}]"
                                            dragText=""
                                            browseText=""
                                            :multiple="false"
                                            :showPrimary="false"
                                            :showEdit="false">
                                        </vue-upload-multiple-image>
                                    </div>
                                        </div>
                                </div>       
                        
                        </div>
                        <div class="col-12 col-md-12 col-lg-12 pt-3">
                            <div class="form-group">
                                <label>Mensagem </label>
                                <textarea v-model="message" class="form-control" rows="6" placeholder="Mensagem"></textarea>
                               
                                <div class="row">
                                    <div class="col-lg-3">
                                        <label>Anexa imagem ou print do erro      </label>
                                        <vue-upload-multiple-image
                                            @upload-success="($formData, $index, $fileList) => uploadImageSuccess(null, 'image_05', $fileList)"
                                            @before-remove="($index, $done, $fileList) => beforeRemove($index, $done, $fileList, 'image_05')"
                                            @edit-image="editImage"
                                            :data-images="image_05"
                                            idUpload="image_05"
                                            editUpload="image_05"
                                            dragText="Clique ou arraste uma image aqui"
                                            browseText="Procurar imagem"
                                            :multiple="false"
                                            :showPrimary="false"
                                            :showDelete="id ? false : true"
                                            :showEdit="false">
                                        </vue-upload-multiple-image>
                                    </div>
                                 
                                </div>
                                <small id="textHelp" class="form-text text-muted">Insira os detalhes de sua solicitação. Tente ser o mais específico possível para que possamos ser mais efetivos no seu atendimento. Inclua qualquer detalhe adicional que você considere relevante como exemplos, prints, scripts e logs. Um membro de nossa equipe de suporte responderá assim que possível.</small>
                            </div>
                        </div>
                       
              
                        <div class="col-12 text-right">
                            <button type="button" @click="sendMessage" class="btn btn-info">Enviar</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="card card-bg-trans">
                            <div class="card-body pt-0 px-0">
                                <form action="" class="px-2 needs-validation" novalidate="">
                                    <div class="form-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <h5 class="font-22 color-1 font-b-6">Estamos aqui para te ajudar. 😉</h5>
                                            </div>
                                            <div class="col-12 pt-3">
                                                <div class="form-group">
                                                    <label>Assunto </label>
                                                    <input :disabled="!isQsuiteAdmin && id" type="text" id="inputSuccess1" class="form-control" placeholder="Identificação do chamado" v-model="ticket.subject">
                                                    <div class="invalid-feedback pl-4 pb-3">Por favor! Informe o assunto.</div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-7 col-lg-8">
                                                <div class="form-group">
                                                    <label>Sobre o que se trata seu chamado?</label>
                                                    <select :disabled="!isQsuiteAdmin  && id"  v-model="ticket.about_id" required class="custom-select mr-sm-2 select-disabled" id="inlineFormCustomSelect">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="about in all_about " :key="about.id" :value="about.id">{{about.name}}</option>
    
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 col-lg-4" v-show="isQsuiteAdmin">
                                                <div class="form-group">
                                                    <label>PROCEDENTES</label>
                                                    <select v-model="ticket.from_id" required class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="about in all_from " :key="about.id" :value="about.id">{{about.name}}</option>
    
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-4 col-lg-4" v-show="isQsuiteAdmin">
                                                <div class="form-group">
                                                    <label>Previsão de entrega </label>
                                                    <input type="datetime-local" class="form-control" v-model="ticket.prevision_finisher">
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-3 col-lg-4"  v-show="isQsuiteAdmin">
                                                <div class="form-group">
                                                    <label>Status</label>
                                                    <select v-model="ticket.status_id" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="item in all_status " :key="item.id" :value="item.id">{{item.name}}</option>
    
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-3 col-lg-4">
                                                <div class="form-group">
                                                    <label>Módulo</label>
                                                    <select :disabled="!isQsuiteAdmin  && id" v-model="ticket.module_id" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="item in all_modules " :key="item.id" :value="item.id">{{item.name}}</option>
    
                                                    </select>
                                                </div>
                                            </div>

                                            <div class="col-12 col-md-3 col-lg-4"  v-show="isQsuiteAdmin">
                                                <div class="form-group">
                                                    <label>Prioridade</label>
                                                    <select v-model="ticket.priority_id" class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="item in all_priorities " :key="item.id" :value="item.id">{{item.name}}</option>
    
                                                    </select>
                                                </div>
                                            </div>

                                      
                                            <div class="col-12 py-2" v-show="isQsuiteAdmin" >
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                                                    </div>
                                                    <input  type="text" class="form-control" placeholder="Procurar" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1">
                                                    <div class="col-12">
                                                        
                                                        <div class="custom-control custom-radio" v-for="company in all_companies" :key="company.id">
                                                            <input :id="company.id" v-model="company_id" :value="company.id" name="company_id" type="radio" class="custom-control-input">
                                                            <label :for="company.id" class="custom-control-label check-label" >{{company.name}}</label>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        

                                            <div class="col-12" v-if="isQsuiteAdmin  && id">
                                                <button type="button" @click="save" class="btn btn-info">Alterar</button>
                                            </div>

                                            <div class="col-12 col-md-12 col-lg-12 pt-1">
                                                <div class="form-group">
                                                    <label>Descrição </label>
                                                    <textarea :disabled="!isQsuiteAdmin  && id" class="form-control" v-model="ticket.description" rows="6" placeholder="Mensagem"></textarea>
                                                    <small id="textHelp" class="form-text text-muted">Insira os detalhes de sua solicitação. Tente ser o mais específico possível para que possamos ser mais efetivos no seu atendimento. Inclua qualquer detalhe adicional que você considere relevante como exemplos, prints, scripts e logs. Um membro de nossa equipe de suporte responderá assim que possível.</small>
                                                </div>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-3">
                                                <label>Anexa imagem ou print do erro                                                </label>
                                                <vue-upload-multiple-image
                                                    @upload-success="($formData, $index, $fileList) => uploadImageSuccess(null, 'image_01', $fileList)"
                                                    @before-remove="($index, $done, $fileList) => beforeRemove($index, $done, $fileList, 'image_01')"
                                                    @edit-image="editImage"
                                                    :data-images="ticket.images.image_01"
                                                    idUpload="image_01"
                                                    editUpload="image_01"
                                                    dragText="Clique ou arraste uma image aqui"
                                                    browseText="Procurar imagem"
                                                    :multiple="false"
                                                    :showPrimary="false"
                                                    :showDelete="id ? false : true"
                                                    :showEdit="false">
                                                </vue-upload-multiple-image>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-3" v-show="false">
                                                <label>Anexa imagem ou print do erro     </label>
                                                <vue-upload-multiple-image
                                                    @upload-success="($formData, $index, $fileList) => uploadImageSuccess(null, 'image_02', $fileList)"
                                                    @before-remove="($index, $done, $fileList) => beforeRemove($index, $done, $fileList, 'image_02')"
                                                    @edit-image="editImage"
                                                    :data-images="ticket.images.image_02"
                                                    idUpload="image_02"
                                                    editUpload="image_02"
                                                    dragText="Clique ou arraste uma image aqui"
                                                    browseText="Procurar imagem"
                                                    :multiple="false"
                                                    :showPrimary="false"
                                                    :showEdit="false">
                                                </vue-upload-multiple-image>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-3" v-show="false">
                                                <label>Anexa imagem ou print do erro      </label>
                                                <vue-upload-multiple-image
                                                    @upload-success="($formData, $index, $fileList) => uploadImageSuccess(null, 'image_03', $fileList)"
                                                    @before-remove="($index, $done, $fileList) => beforeRemove($index, $done, $fileList, 'image_03')"
                                                    @edit-image="editImage"
                                                    :data-images="ticket.images.image_03"
                                                    idUpload="image_03"
                                                    editUpload="image_03"
                                                    dragText="Clique ou arraste uma image aqui"
                                                    browseText="Procurar imagem"
                                                    :multiple="false"
                                                    :showPrimary="false"
                                                    :showEdit="false">
                                                </vue-upload-multiple-image>
                                            </div>
                                            <div class="col-12 col-md-3 col-lg-3" v-show="false">
                                                <label>Anexa imagem ou print do erro     </label>
                                                <vue-upload-multiple-image
                                                    @upload-success="($formData, $index, $fileList) => uploadImageSuccess(null, 'image_04', $fileList)"
                                                    @before-remove="($index, $done, $fileList) => beforeRemove($index, $done, $fileList, 'image_04')"
                                                    @edit-image="editImage"
                                                    :data-images="ticket.images.image_04"
                                                    idUpload="image_04"
                                                    editUpload="image_04"
                                                    dragText="Clique ou arraste uma image aqui"
                                                    browseText="Procurar imagem"
                                                    :multiple="false"
                                                    :showPrimary="false"
                                                    :showEdit="false">
                                                </vue-upload-multiple-image>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-actions">
                                        <div class="text-right" type="button" v-show="!id">
                                            <button type="reset" class="btn btn-dark">Cancelar</button>
                                            <button  @click="save" type="button" class="btn btn-info">Cadastrar</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <footer class="footer text-center text-muted">© 2021 | QSuíte _Hub de integração de dados. Todos os direitos reservados. <a href="" target="_blank">Termos de uso</a> | <a href="" target="_blank">Política de privacidade.</a></footer></div>
    </template>
    <script>
    import VueUploadMultipleImage from "vue-upload-multiple-image";
    import TicketAbout from "@/services/resources/TicketAbout";
    import TicketModule from "@/services/resources/TicketModule";
    import Ticket from "@/services/resources/Ticket";
    import TicketMessage from "@/services/resources/TicketMessage";
    import TicketFrom from  "@/services/resources/TicketFrom";
    import TicketStatus from "@/services/resources/TicketStatus";
    import TicketPriority from "@/services/resources/TicketPriority";
    import CompanyService from '@/services/resources/CompanyService';

    const serviceAbout = TicketAbout.build();
    const serviceModule = TicketModule.build();
    const service = Ticket.build();
    const serviceMessage = TicketMessage.build();
    const serviceFrom = TicketFrom.build();
    const serviceStatus = TicketStatus.build();
    const servicePriority = TicketPriority.build();
    const serviceCompany = CompanyService.build();
    
    export default {
       components:{
            VueUploadMultipleImage
       },
       data(){
           return{
                company_id: null,
               ticket: {
                status_id: null,
                prevision_finisher: null,
                priority_id: null,
                    from_id: null,
                   subject: null,
                   description: null,
                   about_id: null,
                   from_id: null,
                   status_id: null,
                   module_id: null,
                   priority_id: null,
                   company_id: null,
                   prevision_finisher: null,
                   images: {
                    image_01: [],
                    image_02: [],
                    image_03: [],
                    image_04: [],

                    //image message,
                    image_05: [],
                    image_06: []
                   },
                   id: null,
                   messages: []
               },
               message: '',
               all_about: [],
               all_modules: [],
               all_from: [],
               all_status: [],
               all_priorities: [],
               id: null,
               pages:[
                    {
                        name: 'dashboard',
                        to: 'Dashboard'
                    },
                    {
                        name: 'novo produto',
                        to: 'ProductNew'
                    }
                ],
                menu:[
                    {
                        name: 'Novo produto',
                        to: 'ProductNew'
                    },
                    {
                        name: 'Produtos',
                        to: 'Product'
                    }
                ],
                all_companies: []
           }
       },
       methods:{

            sendMessage() {

                let images = [];

                console.log(this.ticket.images);

                if(this.ticket.images.image_05.length) {
        
                    images.push(this.ticket.images.image_05[0]);
                }

                serviceMessage.update({id: this.id, message: this.message, images}).then(response => {
                    this.$bvToast.toast('Mensagem enviada com sucesso!', {
                            title: 'Mensagem enviada',
                            autoHideDelay: 5000,
                            type: 'success'
                        });
                        this.message = '';
                        this.ticket.images.image_05 = []
                  

                      this.fetchTicket();
                });

            },

            fetchPriorities() {
                servicePriority.get('').then(response => {
                    this.all_priorities = response;
                });
            },

            fetchStatus() {
                serviceStatus.get('').then(response => {
                    this.all_status = response;
                });
            },

            fetchFrom() {
                serviceFrom.get('').then(response => {
                    this.all_from = response;
                });
            },
      
            fetchTicket() {
             
                service.read({id: this.id}).then(response => {
                    this.ticket = {...response, images:  {
                        image_01: [],
                        image_02: [],
                        image_03: [],
                        image_04: [],
                        image_05: [],
                        image_06: []
                    }};

                    this.company_id = response.company_id;
              
                    let dateTreated = '', hour = '';
                    this.ticket.messages = response.messages.map(item => {

                        hour = item.created_at.split('T')[1].slice(0, 8);
                        dateTreated = item.created_at.split('T')[0].split('-');
                        dateTreated = `${dateTreated[2]}/${dateTreated[1]}/${dateTreated[0]}`;
                        return {
                            ...item,
                            created_at: `${dateTreated} | ${hour}`
                        }
                    });

                    response.images.forEach((item, index) => {
                        this.ticket.images[`image_0${index + 1}`] = [{path: item.path}];
                    });
            
                });
 
            },

            fetchCompanies() {
               serviceCompany.get('').then(response => {
                    this.all_companies = response;
                    if(!this.$store.getters.isQsuiteAdmin)
                    this.company_id = response[0].id
                });
            },
            getAbout() {
                serviceAbout.get('')
                .then(resp => {
                    this.all_about = resp;
                })
                .catch(err => {
                    console.log(err)
                })

            },
            getModules() {
                serviceModule.get('')
                .then(resp => {
                    this.all_modules = resp;
                })
                .catch(err => {
                    console.log(err)
                })
            },
            uploadImageSuccess(formData, index, fileList) {
                this.ticket.images[index] = fileList;
            },
            beforeRemove(index, done, fileList, nameIndex) {

                var r = confirm("Remover imagem?");
                if (r == true) {
                    this.ticket.images[nameIndex].splice(index, 1);
                    done();
                }
            },
            editImage(formData, index, fileList) {
                this.product.image = fileList;
            },
           save(e){
            e.preventDefault();

            const images = [];
                    Object.keys(this.ticket.images).map(key => {
                        if(this.ticket.images[key].length && (key != 'image_05' && key != 'image_06')) images.push(this.ticket.images[key][0]);
                    });

            if(this.id){
                    this.ticket.id = this.id;
                   
                    service
                    .update({
                        ...this.ticket,
                        images,
                        company_id: this.company_id
                    })
                    .then(resp => {
                        this.$bvToast.toast('Seu chamado foi atualizado com sucesso!', {
                            title: 'Chamado atualizado',
                            autoHideDelay: 5000,
                            type: 'success'
                        })
                    })
                    .catch(err => {
                    })
                }else{
                    service
                    .create({
                        ...this.ticket,
                        images,
                        company_id: this.company_id
                    })
                    .then(resp => {
                        this.$bvToast.toast('Seu chamado foi criado com sucesso!', {
                            title: 'Chamado criado',
                            autoHideDelay: 5000,
                            type: 'success'
                        });
                        setTimeout(() => {
                            window.location.href = '/suporte/editar/' + resp.id;
                        }, 2500);
                     
                    })
                    .catch(err => {
                    })
                }
           },
        
       },
       mounted(){
           this.id = this.$route.params.id;
           this.getAbout();
           this.getModules();  

           if(this.id) {
            this.fetchTicket();
           }

           this.fetchFrom();

           if(this.$store.getters.isQsuiteAdmin) {
          
               
                this.fetchStatus();
                this.fetchPriorities();
                
           }

           if(this.$store.getters.isQsuiteAdmin) {
            this.fetchCompanies();
           }
       },
       computed: {
            isQsuiteAdmin() {
                return this.$store.getters.isQsuiteAdmin
            }
       }
    }
    </script>

    <style scoped>
    select:disabled {
        background-color: lightgrey!important;
        color: black;
    }
    </style>